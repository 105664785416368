import { isExperienceEditorActive } from "@sitecore-jss/sitecore-jss-react";
import "./webcomponents";
import "./index.scss";
import "./index-override.scss";
import "./coolicons.css";
import i18ninit from "./i18n";
import config from "./temp/config";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

let initLanguage = config.defaultLanguage;

let __JSS_STATE__ = null;
const ssrRawJson = document.getElementById("__JSS_STATE__");
if (ssrRawJson) {
  __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);
}
if (__JSS_STATE__) {
  // set i18n language SSR state language instead of static config default language
  initLanguage = __JSS_STATE__.sitecore.context.language;
}

const isLegacy = document.createElement("script").noModule === undefined;

if (isLegacy) {
  import(/* webpackChunkName: 'legacy-support' */ "./polyfills.js").then(() =>
    console.info("Legacy browsers support enabled")
  );
}

import("./main.js").then();

/*
  App Rendering
*/
// initialize the dictionary, then render the app
// note: if not making a multlingual app, the dictionary init can be removed.
// i18ninit(initLanguage, undefined, __JSS_STATE__?.sitecore?.context?.ContextExtensions?.appName?.value)

// THIS SCRIPT WILL LOAD ONLY WHEN THE EXPERIENCE EDITOR IS ENABLED
if (isExperienceEditorActive()) {
  import("./experience-editor-patches/index.js").then(() =>
    console.info("EXPERIENCE EDITOR PATCHES LOADED")
  );
}

window.onscroll = function () {
  scrollFunction();
};

function scrollFunction() {
  var element = document.getElementById("navigation-id");
  var logolist = document.getElementById("logo-items");
  var navigationMenuMobile = document.getElementById("hamburgerMenuMain");
  if (element && logolist && !navigationMenuMobile) {
    if (
      document.body.scrollTop > 50 ||
      document.documentElement.scrollTop > 50
    ) {
      document.querySelector("header").classList.add("shrink-content");
      element.classList.add("headroom--not-top");
      logolist.classList.add("shrink-content");
    } else {
      element.classList.remove("headroom--not-top");
      document.querySelector("header").classList.remove("shrink-content");
      logolist.classList.remove("shrink-content");
    }
  }
}
